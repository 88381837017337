.wrapper {
  position: relative;
  /* opacity: 0; */
  width: 100%;
  height: 100%;
  padding: 4% 4%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: 280px;
  gap: 20px;
}

.card {
  position: relative;
  background-color: var(--dark-color-second);
  padding: 4% 6%;
  border-radius: 10px;
  /* transition-duration: 0.8s; */
  transition: 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.card:hover {
  background-color: var(--dark-color-fifth);
  /* transform: rotateX(180deg); */
}

.card h3 {
  font-size: 2rem;
  color: var(--brand-color-light-fourth);
  width: 85%;
}

.card span {
  color: var(--brand-color-light-first);
}

.card span a {
  color: var(--brand-color-light-first);
}

.hrThin {
  position: relative;
  margin: 4% 0;
  width: 90%;
  border: none;
  height: 0.2px;
  background-color: var(--light-color-first);
}

.card p {
  color: var(--light-color-second);
  font-size: smaller;
}

.card img {
  display: inline;
  position: absolute;
  bottom: 8%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.card button {
  position: absolute;
  margin-top: 30px;
  bottom: 8%;
  right: 6%;
  padding: 15px;
  background-color: var(--brand-color-dark-first);
  color: var(--brand-color-dark-fourth);
  font-weight: 500;
  border-radius: 20px;
  border: none;
  transition-duration: 0.5s;
}

.card button a {
  color: var(--brand-color-dark-fourth);
}

.card button:hover {
  border: 1px solid var(--brand-color-light-first);
  background-color: transparent;
  color: var(--brand-color-dark-fourth);
}

.number {
  position: absolute;
  top: 4%;
  right: 6%;
  font-size: 3rem;
  color: var(--brand-color-dark-third);
  font-weight: 500;
}

.hostingBoxOuter {
  display: inline-block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.534);
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
}

.hostingBoxInner {
  display: inline-block;
  position: absolute;
  height: 500px;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--brand-color-dark-fourth);
  background-color: var(--dark-color-second);
  padding: 4% 6%;
  border-radius: 30px;
  /* transition-duration: 0.8s; */
  transition: 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.hostingBoxInner h1 {
  margin-top: 5%;
}

.hostingBoxInner p {
  color: var(--light-color-second);
}

.closeBtn {
  display: inline-block;
  position: absolute;
  top: 30px;
  right: 50px;
  font-size: 2.5rem;
  color: var(--brand-color-dark-first);
}

.reachMe {
  color: var(--brand-color-light-first) !important;
  font-size: small;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 300px) and (max-width: 600px) {
  .wrapper {
    padding: 15% 2%;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    grid-auto-rows: 300px;

    gap: 30px;
  }
  .card h3 {
    font-size: 1.6rem;
  }
  .card p {
    font-size: smaller;
  }

  .hostingBoxInner {
    height: 600px;
    width: 90%;
  }

  .hostingBoxInner h1 {
    margin-top: 25%;
  }

  .closeBtn {
    display: inline-block;
    position: absolute;
    top: 15px;
    right: 30px;
    font-size: 2.5rem;
    color: var(--brand-color-dark-first);
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .wrapper {
    padding: 6% 4%;
    gap: 30px;
    grid-auto-rows: 310px;
  }
  .card h3 {
    font-size: 1.8rem;
  }
  .card p {
    font-size: 0.9rem;
  }

  .hostingBoxInner {
    height: 550px;
    width: 90%;
  }

  .hostingBoxInner h1 {
    margin-top: 15%;
  }

  .closeBtn {
    display: inline-block;
    position: absolute;
    top: 15px;
    right: 30px;
    font-size: 2.5rem;
    color: var(--brand-color-dark-first);
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .wrapper {
    padding: 8% 4%;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  }
  /* .card p {
    font-size: smaller;
  } */
}

/* body {
  background-color: #121214;
  background-image: -webkit-linear-gradient(#121214, #343437);
  background-image: linear-gradient(#121214, #343437);
} */

.container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 3% 6%;
  scroll-snap-type: y mandatory;
  /* overflow-y:overlay; */
  overflow: overlay;
  scroll-behavior: smooth;
}

.title {
  display: block;
  text-align: center;
}

.title h1 {
  position: relative;
  margin: 4% 0;
  color: var(--brand-color-dark-first);
  font-size: 6rem;
  font-weight: 600;
  z-index: 10;
}

.sectionTitle {
  display: block;
  margin: 2%;
}

.sectionTitle h2 {
  display: inline-block;
  padding: 0.5% 1%;
  color: var(--brand-color-dark-fourth);
  font-size: 2.5rem;
  border-left: 1px solid var(--brand-color-dark-first);
  border-bottom: 1px solid var(--brand-color-dark-first);
  transition-duration: 1s;
}

.sectionTitle h2:hover {
  scale: 1.05;
  box-shadow: -4px 6px 0px 1px var(--brand-color-dark-first), -10px 12px 2px 0px var(--brand-color-dark-fourth);
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}



::-webkit-scrollbar-track {
  background-color:var(--solid-color-first);
}

::-webkit-scrollbar-thumb {
  background: #5a5a5a66;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--brand-color-dark-first);
}

@media only screen and (max-width: 360px) {
  .container {
    padding: 8% 6%;
  }
}

@media only screen and (min-width: 361px) and (max-width: 400px) {
  .container {
    padding: 8% 6%;
  }
}

@media only screen and (min-width: 401px) and (max-width: 480px) {
  .container {
    padding: 6% 6%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 580px) {
  .container {
    padding: 6% 6%;
  }
}

@media only screen and (min-width: 581px) and (max-width: 770px) {
  .container {
    padding: 5% 6%;
  }
}

@media only screen and (min-width: 770px) and (max-width: 1024px) {
  .container {
    padding: 4% 6%;
  }
}
